<template>
  <div class="index">
    <div class="successicon">
      <div class="weixin"
           v-if="isWhat=='weixin'">
        <van-icon name="passed" />
      </div>
      <div class="alipay"
           v-if="isWhat=='alipay'">
        <van-icon name="passed" />
      </div>
    </div>
    <van-divider :style="{borderColor: '#000'}">
      <div class="order-amount">
        <svg class="icon goldCoin"
             aria-hidden="true">
          <use xlink:href="#icon-anonymous-iconfont"></use>
        </svg>
        {{amount}}
      </div>
    </van-divider>
    <div class="ad">
      <img src="https://b.bdstatic.com/searchbox/image/cmsuploader/20210714/1626266679695842.png"
           alt="">
    </div>
  </div>
</template>

<script>
import { Icon, Divider } from 'vant';
import { reactive, toRefs } from 'vue';
export default {
  components: {
    [Icon.name]: Icon,
    [Divider.name]: Divider,
  },
  setup () {
    const state = reactive({
      queryobj: {},
      amount: ''
    });
    return { ...toRefs(state) }
  },
  created () {
    this.queryobj = this.$route.query
    let ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      this.isWhat = "weixin";
      //没有引入wx api 插入head
      let wxjssdk = document.createElement("script")
      wxjssdk.src = "https://res.wx.qq.com/open/js/jweixin-1.6.0.js"
      document.querySelector("head").appendChild(wxjssdk)
    } else if (ua.match(/alipay/i) == "alipay") {
      this.isWhat = "alipay";
    } else {
      //不是微信和支付宝打开的，提示
      this.nopayshow = true
      return
    }
    this.amount = this.$route.params.amount
  },
  methods: {
    paybtn () {

    },
    refresh () {
      location.replace = location.href
    }
  }
}
</script>
<style lang="less" scoped>
.index {
  .successicon {
    margin: 20px 0 0 0;
    font-size: 80px;
    .weixin {
      color: #44b549;
    }
    .alipay {
      color: #1989fa;
    }
  }
  .order-amount {
    font-size: 24px;
    color: #000;
    .goldCoin {
      position: relative;
      top: 2px;
      width: 20px;
      height: 20px;
    }
  }
  .ad {
    display: block;

    overflow: hidden;
    margin: 50px 10px 10px 10px;
    img {
      border-radius: 12px;
      width: 100%;
      height: 100%;
    }
  }
  text-align: center;
}
</style>
